<i18n locale="th" lang="yaml" src="@i18n/service/service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
service.field.open_by.help : "ระบุผู้รับเรื่องจากลูกค้า"
service.field.open_by.placeholder : "ระบุผู้รับแจ้ง"
service.field.open_date.help : "วันที่ Drone นี้มาถึงศูนย์ซ่อม"
service.field.open_time_range.help : "เวลาที่ Drone มาถึงศูนย์ซ่อม โดยประมาณ"
service.field.delivery_method.help : "รับเครื่องมาโดยวิธีไหน"
service.field.technician.help : "ช่างที่จะดูแลงานซ่อมนี้"
service.field.technician.placeholder : "ระบุช่าง"
service.field.assistant.help : "ผู้ช่วยช่างที่จะดูแลงานซ่อมนี้"
service.field.assistant.placeholder : "ระบุผู้ช่วยช่าง"

service.field.customer.error : "ข้อมูลลูกค้าไม่ครบ"
service.field.customer.help : "ข้อมูลลูกค้าที่นำส่งเครื่อง หรือ แจ้งปัญหา"
service_form.field.open_type.from_service : "รับมาจากศูนย์ {company} ใบงานเลขที่ {serviceNo}"
service_form.remark : "การตั้งค่า \"@:service.field.is_device_only\" จะไม่สามารถแก้ไขได้หลังเปิดใบงานนี้ไปแล้ว"

service.field.is_device_only.help : "ส่งแต่อุปกรณ์อย่างเดียว ไม่จำเป็นต้องส่ง Drone มา เช่น รีโมทเสีย หรือ เครื่องชาร์จเสีย เป็นต้น"

service_task.title : "งานที่ต้องการเข้ารับบริการ"
service_task.title.description : "ถ้าแค่เปลี่ยนอะไหล่ ไม่ต้องระบุงาน (สามารถเพิ่มลดทีหลังได้)"
service_task.repair : "งานซ่อม"
service_task.repair.disabled : "ระบุสำหรับงานซ่อมเท่านั้น"
service_task.maintenance : "ตรวจสภาพ Drone"
service_task.maintenance.not_support : "ยังไม่รองรับงานตรวจสภาพ"
service_task.update_firmware : "เข้ามา Update Firmware"
service_task.update_firmware.message.up_to_date : "Firmware ของ Drone ตัวนี้เป็น Version ล่าสุดแล้ว {firmware}"
service_task.update_firmware.message.suggest : "แนะนำให้ Update Firmware เป็น Version {firmware}"
service_task.update_firmware.message.device_only : "ลูกค้าไม่ได้นำ Drone เข้ารับบริการ"
</i18n>

<template>
	<a-form
		layout="horizontal"
		:label-col="labelCol"
		:wrapper-col="wrapperCol"
		:form="formObj"
		@submit.prevent="handleSubmit">
		<a-form-item v-if="isModeCreate" v-bind="wrapperOnlyLayout" :extra="$t('service.field.is_device_only.help')">
			<a-checkbox v-decorator="[
          'is_device_only',
          {
            valuePropName: 'checked',
            initialValue: false,
          },
        ]"
				@change="handleDeviceOnly">
				{{$t('service.field.is_device_only')}}
			</a-checkbox>
		</a-form-item>

		<hr v-if="isModeCreate" />
		<a-form-item :label="$t('service.field.open_by')"
			:extra="$t('service.field.open_by.help')">
			<UserCompanySelect
				v-decorator="['open_by_id' ,
					{
						initialValue: initialForm.open_by_id,
						rules: [
							{ required: true ,message: $tt('validate.required','service.field.open_by') },
						],
					}]"
				:company-id="currentCompanyId"
				:placeholder="$t('service.field.open_by.placeholder')"/>
		</a-form-item>
		<a-form-item :label="$t('service.field.customer')" :extra="$t('service.field.customer.help')"
			required>
			<ServiceCustomerInput
				v-decorator="[
					'customer' ,
					{
						rules: [
								{ validator: validateCustomerData },
						] ,
					},
				]"
				:owner="droneData.owner"
				:drone-id="droneData.id"/>
		</a-form-item>
		<a-form-item :label="$t('service.field.open_date')"
			:extra="$t('service.field.open_date.help')">
			<MyDatePicker
				v-decorator="['open_date',
					{
						initialValue: initialForm.open_date,
						rules: [
							{ required: true ,whitespace: true ,message: $tt('validate.required','service.field.open_date') },
						] ,
					}]"
			format="DD MMMM YYYY"/>
		</a-form-item>
		<a-form-item :label="$t('service.field.open_time_range')" :extra="$t('service.field.open_time_range.help')">
			<a-radio-group
				v-decorator="['open_time_range',
				{
					initialValue: initialForm.open_time_range,
					rules: [
						{ required: true ,whitespace: true ,message: $tt('validate.required','service.field.open_time_range') },
					] ,
				}]" :options="timeRangeOptions" />
		</a-form-item>
		<hr />
		<a-form-item :label="$t('service.field.open_type')" required>
			<span class="ant-form-text text-primary">
				{{openTypeDisplay}}
			</span>
		</a-form-item>
		<a-form-item :label="$t('service.field.delivery_method')" :extra="$t('service.field.delivery_method.help')"
			required>
			<DeliveryDetailInput
				ref="deliveryDetailRef"
				:is-customer="currentOpenType == 'customer'"/>
		</a-form-item>
		<hr />
		<a-form-item :label="$t('service.field.technician')"
			:extra="$t('service.field.technician.help')">
			<UserCompanySelect
				v-decorator="['technician_id']"
				:company-id="currentCompanyId"
				allow-clear
				:placeholder="$t('service.field.technician.placeholder')"/>
		</a-form-item>
		<a-form-item :label="$t('service.field.assistant')"
			:extra="$t('service.field.assistant.help')">
			<UserCompanySelect
				v-decorator="['assistant_id']"
				:company-id="currentCompanyId"
				allow-clear
				:placeholder="$t('service.field.assistant.placeholder')"/>
		</a-form-item>
		<hr />
		<template v-if="isModeCreate">
			<div class="myform-section-title">
				{{$t('service_task.title')}}
				<div class="myform-section-description">
					{{$t('service_task.title.description')}}
				</div>
			</div>
			<div class="service-task-block">
				<a-checkbox :checked="serviceTasks.isRepair" @change="handleRepairChange">
					{{$t('service_task.repair')}}
				</a-checkbox>
				<BlockPane :block="!serviceTasks.isRepair">
					<span slot="message">{{$t('service_task.repair.disabled')}}</span>
					<ServiceRepairInput ref="serviceRepairRef" :disable-mode="true" class="service_repair-form" :can-update="serviceTasks.isRepair" />
				</BlockPane>
			</div>
			<div class="service-task-block">
				<a-checkbox v-model="serviceTasks.isMaintenance" :disabled="true">
					{{$t('service_task.maintenance')}}
				</a-checkbox>
				<div class="ant-form-extra">{{$t('service_task.maintenance.not_support')}}</div>
			</div>
			<div class="service-task-block">
				<a-checkbox v-model="serviceTasks.isUpdateFirmware" :disabled="firmwareCheckDetail.disabled">
					{{$t('service_task.update_firmware')}}
				</a-checkbox>
				<div v-show="$notEmpty(firmwareCheckDetail.message)" class="service_repair-firmware-message-pane">
					<span v-if="firmwareCheckDetail.disabled" class="ant-form-extra">
						{{firmwareCheckDetail.message}}
					</span>
					<span v-else class='service_repair-firmware-message'>
						<a-icon type="exclamation"  /> {{firmwareCheckDetail.message}}
					</span>
				</div>
			</div>
			<hr />
		</template>
		<div class="service-task-block">
			<slot name="submitSlot" :formLoading="loading">
				<a-button
					:loading="loading"
					type="info"
					html-type="submit"
					class="btn-submit"
					size="large">
					{{$t('common.save')}}
				</a-button>
			</slot>
		</div>
		<a-form-item v-if="isModeCreate" v-bind="wrapperOnlyLayout">
			<div class="text-right text-error">
				{{$t('service_form.remark')}}
			</div>
		</a-form-item>
	</a-form>
</template>

<script>
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import LoginInfoMixin from '@mixins/LoginInfoMixin.vue'
import {Checkbox,Radio} from "ant-design-vue"
import UserCompanySelect from "@components/user/UserCompanySelect.vue"
import MyDatePicker from "@components/input/MyDatePicker.vue"
import ServiceCustomerInput from "./ServiceCustomerInput.vue"
import _get from "lodash/get"
import { isStringEmpty, timeRange } from '@utils/stringUtil'
import {updateFieldsValue,getEnumSelectOptions} from "@utils/formUtil"
import {transformToJsonProp,emptyDrone} from "@utils/objectUtil"
import BlockPane from "@components/common/BlockPane.vue"
import ServiceRepairInput from "@components/service/ServiceRepairInput.vue"
import {mapGetters} from "vuex"
import DeliveryDetailInput from '@components/input/DeliveryDetailInput.vue'

export default {
	components : {
		UserCompanySelect,MyDatePicker,
		ServiceCustomerInput,
		BlockPane,  ServiceRepairInput,
		"a-checkbox" : Checkbox , "a-radio-group" : Radio.Group ,
		DeliveryDetailInput,
	} ,
	mixins : [HasAntdFormMixin,LoginInfoMixin] ,
	props : {
		mode : {
			type : String,
			default : 'create'
		} ,
		loading : {
			type : Boolean ,
			default : false,
		}
	} ,
	data() {
		return {
			droneData : emptyDrone() ,
			fromService : {},
			currentOpenType : undefined ,
			currentDeviceOnly : false,

			currentCompanyId : undefined,

			serviceTasks : {
				isRepair : true,
				isMaintenance : false,
				isUpdateFirmware : false,
			} ,
			firmwareCheckDetail : {
				disabled : false,
				message : undefined,
			} ,
			initialForm : {
				open_by_id : undefined,
				technician_id : undefined,
				open_date : undefined,
				open_time_range : undefined,
				open_type : undefined,
			}
		}
	} ,
	computed : {
		...mapGetters('drone',['getLatestFirmwareBySet']) ,
		isModeCreate() {
			return this.mode == 'create' || this.mode == 'create_service'
		} ,
		timeRangeOptions() {
			const options = ['morning','noon','afternoon','evening']
			return getEnumSelectOptions(this,'time_range',options)
		} ,
		openTypeDisplay() {
			if (this.currentOpenType == 'from_service') {
				if (this.fromService && this.fromService.id > 0) {
					return this.$t('service_form.field.open_type.from_service',{
						company : this.fromService.company.name,
						serviceNo : this.fromService.service_no
					})
				} else{
					return this.$tenum('service.open_type','from_service')
				}
			} else {
				return this.$tenum('service.open_type',this.currentOpenType)
			}
		}
	} ,
	mounted() {
		if (this.isModeCreate) {
			this.currentOpenType = 'customer'
			this.initialForm = {
				...this.createInitialFormValue() ,
			}
		}
	} ,
	methods : {
		createInitialFormValue() {
			const defaultOpenDate = this.$dayjs().format('YYYY-MM-DD')
			const defaultOpenTime = timeRange(this.$dayjs().hour())
			const userId = this.currentUser.id
			return {
				open_by_id : userId,
				technician_id : userId,
				open_date : defaultOpenDate,
				open_time_range : defaultOpenTime,
				open_type : this.currentOpenType,
			}
		} ,
		handleRepairChange(event) {
			this.serviceTasks.isRepair = event.target.checked
		} ,
		validateCustomerData(rule,value,callback) {
			const fail = isStringEmpty(_get(value,'customer_name')) ||
				isStringEmpty(_get(value,'customer_phone')) ||
				isStringEmpty(_get(value,'customer_province'))
			if (fail) {
				callback(this.$t('service.field.customer.error'))
			} else {
				callback()
			}
		} ,
		handleDeviceOnly(event) {
			this.currentDeviceOnly = event.target.checked
			this.checkFirmware()
		} ,
		checkFirmware() {
			if (this.currentDeviceOnly) {
				this.firmwareCheckDetail.disabled = true
				this.serviceTasks.isUpdateFirmware = false
				this.firmwareCheckDetail.message = this.$t('service_task.update_firmware.message.device_only')
			} else if (_get(this.droneData,'firmware.id',-1) > 0) {
				const currentFirmwareId = this.droneData.firmware.id
				const firmwareSetId = _get(this.droneData,"model.firmware_set_id")
				const latestFirmware = this.getLatestFirmwareBySet(firmwareSetId)
				if (latestFirmware === false) {
					this.firmwareCheckDetail.disabled = false
					this.firmwareCheckDetail.message = undefined;
				} else if (latestFirmware.id == currentFirmwareId) {
					this.firmwareCheckDetail.disabled = true
					this.serviceTasks.isUpdateFirmware = false
					this.firmwareCheckDetail.message = this.$t('service_task.update_firmware.message.up_to_date',{firmware : latestFirmware.name})
				} else {
					this.firmwareCheckDetail.disabled = false
					this.firmwareCheckDetail.message = this.$t('service_task.update_firmware.message.suggest',{firmware : latestFirmware.name})
				}
			} else {
				this.firmwareCheckDetail.disabled = false
				this.firmwareCheckDetail.message = undefined;
			}
		} ,
		handleSubmit() {
			this.formObj.validateFields((err,values) => {
				const deliveryInput = this.$refs.deliveryDetailRef.getData()
				if(!err && this.$notEmpty(deliveryInput.type)) {
					const formData = {
						...values,
						...values.customer ,
						delivery_method : deliveryInput.type ,
						deliveryData : deliveryInput.data ,
						assistant_id : values.assistant_id ? values.assistant_id : null,
						techinician_id : values.techinician_id ? values.techinician_id : null,
						drone_service_drone_instance_id : this.droneData.drone.id ,
						open_type : this.currentOpenType ,
					}
					delete formData.customer
					transformToJsonProp(formData,'deliveryData','delivery_json')
					if (this.isModeCreate) {
						const payload = {service : formData}
						if (this.serviceTasks.isRepair && this.$refs.serviceRepairRef) {
							payload.repair = this.$refs.serviceRepairRef.getData()
						}
						if (this.serviceTasks.isUpdateFirmware)
							payload.firmware = true
						if (this.serviceTasks.isMaintenance)
							payload.maintenance = true
						this.$emit('submit',payload)
					} else {
						this.$emit('submit',formData)
					}

				} else {
					this.displayValidateErrorMessage()
				}
			})
		} ,
		updateDroneData(droneData) {
			this.droneData = {...droneData}
			this.checkFirmware()
		} ,
		updateReferredServiceForm(droneData,service,repair,fromService) {
			this.currentOpenType = service.open_type
			const initForm = this.createInitialFormValue()
			const copyService = {
				...service ,
				droneData : {...droneData},
				...initForm
			}
			this.fromService = {...fromService}
			this.updateServiceForm(copyService)
			if (repair) {
			 	this.$refs.serviceRepairRef.setData(repair)
			}
		} ,
		updateServiceForm(service) {
			this.currentCompanyId = service.company_id
			this.droneData = {...service.droneData}
			this.checkFirmware()
			const formData = {
				...service,
				customer : {
					customer_name : service.customer_name,
					customer_phone : service.customer_phone,
					customer_province : service.customer_province
				} ,
			}
			delete formData.droneData

			if (!formData.assistant_id) {
				delete formData.assistant_id
			}
			if (!formData.techinician_id) {
				delete formData.techinician_id
			}
			this.currentOpenType = service.open_type
			updateFieldsValue(this.formObj,formData)
			this.$nextTick(()=>{
				this.$refs.deliveryDetailRef.setData(service.delivery_method,service.deliveryData)
			})
		} ,
		formErrors(error) {
			this.formSubmitErrors(error)
		}

	}
}
</script>

<style lang="less" scoped>
.service-task-block {
	margin-bottom : 16px;
	margin-left : calc(20% / 2);

	.mobile & {
		margin-left : 0;
	}
}
.service_repair-form {
	padding : 16px 32px;
}
.service_repair-firmware-message-pane {
	font-size : 1.1em;
	margin-top : 4px;
}
.service_repair-firmware-message {
	color : @warning-color;
}
</style>
